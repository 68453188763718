import React, { useState } from "react";
import * as styles from "./component-dropdown.module.scss";
import { RichText } from "../../Layout/RichText/RichText";
import { IoIosArrowUp } from "@react-icons/all-files/io/IoIosArrowUp";

const ComponentDropdown = ({ relatedDropdown }) => {
  const Dropdown = ({ title, description, itemKey }) => {
    const [activeItem, setActiveItem] = useState(null);
    const toggleItem = index => {
      setActiveItem(activeItem === index ? null : index);
    };
    return (
      <div className={styles.dropdown}>
        <div onClick={() => toggleItem(itemKey)} className={styles.qus}>
          {title}
          <IoIosArrowUp className={activeItem === itemKey ? styles.open : ''} />
        </div>
        {activeItem === itemKey && (
          <div className={styles.subtitle}>
            <RichText content={description} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {relatedDropdown.map((item, id) => {
        const { title, description } = item;
        return <Dropdown title={title} description={description} itemKey={id} key={id} />;
      })}
    </div>
  );
};

export default ComponentDropdown;


