import React from "react"
import GridSection from "../GridSection/GridSection"
import * as styles from "./alternativeicon.module.scss"

const alternativeicon = ({ relatedsection, slug, slugTitle }) => {
  return (
    <>
      <div className={styles.sectionContainer}>
        {relatedsection.map(related => {
          return (
            <GridSection
              title={related?.title}
              image={related.gridImages}
              backgroundImage={related.backgroundImage}
              pdfdownloadsection={related?.pdfdownloadsection}
              imageSize={related?.imageSize}
              pdFtitle={related?.pdFtitle}
            />
          )
        })}
      </div>
      {slugTitle && (
        <div className={styles.dowloadpdf}>
          <a target="_blank" href={slug}>
            <p>{slugTitle}</p>
          </a>
        </div>
      )}
    </>
  )
}

export default alternativeicon
