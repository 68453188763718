import React from "react";
import { RichText } from "../../components/Layout/RichText/RichText";
import * as styles from './article-content-columns.module.scss';
const ArticleContentColumns = ({
    leftColumn,
    rightColumn
}: {
    leftColumn: any,
    rightColumn: any
}) => {
    return (
        <div className={styles.contentContainer}>
            <div className={styles.columns}>
                {
                    leftColumn &&
                    <div className={`${`styles.leftContent`} ${styles.section}`}>
                        <RichText content={leftColumn} />
                    </div>
                }
                {
                    rightColumn &&
                    <div className={`${`styles.rightColumn`} ${styles.section}`}>
                        <RichText content={rightColumn} />
                    </div>
                }
            </div>
        </div>
    )

}

export default ArticleContentColumns