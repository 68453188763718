import React from "react"
import { RichText } from "../../components/Layout/RichText/RichText"

import * as styles from "./reference-content.module.scss"

const ReferenceContent = ({
  content,
  description,
  alignment,
  sourceText
}: {
  content: any
  description: any
  alignment?: String
  sourceText: any
}) => {
  const extraClass = alignment ? alignment.toLocaleLowerCase() : null


  if (content) {
    return (
      <div className={`${styles.container} ${styles[extraClass]}`}>
        <div className={styles.articleContent}>
          <RichText content={content} />
        </div>
        {sourceText && <div className={styles.sourceText}>
                <RichText content={sourceText}/>
                </div>}
      </div>
    )
  } else {
    return (
      <div>
       { description && <div className={`${styles.container} ${styles[extraClass]}`}>
          <div className={styles.descriptionContent}>
            <RichText content={description} />
          </div>
        </div> }
      </div>
    )
  }
}

export default ReferenceContent;
