import React, { useState } from "react"
import ComponentDropdown from "../ComponentDropdown/ComponentDropdown"
import * as styles from "./section-dropdown.module.scss"
// import { IoIosArrowDropdown } from "@react-icons/all-files/io/IoIosArrowDropdown"
import { IoIosArrowUp } from "@react-icons/all-files/io/IoIosArrowUp";


const SectionDropdown = ({ section }) => {
  const { title, gridItems } = section
  const [open, setOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(null)

  const toggleItem = index => {
    if (activeItem === index) {
      setActiveItem(null)
    } else {
      setActiveItem(index)
    }
  }

  return (
    <section className={styles.section}>
      {gridItems.map((item, id) => {
        const { title, relatedDropdown } = item
        return (
          <div key={id}  className={styles.container}>
            <div className={styles.block} >
                <div className={styles.title} onClick={() => toggleItem(id)}>
                <h2 className={styles.heading}>{title} </h2>
                <IoIosArrowUp className={activeItem === id ? styles.open : ''}/>
                </div>
                {activeItem === id && (
                <ComponentDropdown relatedDropdown={relatedDropdown} />
                )}
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default SectionDropdown
