import React from "react"
import * as styles from "./ArticleContentLogo.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"

const ArticleContentLogo = ({logoimage,slug}) => {
  return (
   <div className={styles.imageContainer}>
     {slug ? <Link target="_blank" to={slug}><Img fluid={logoimage?.fluid} alt={logoimage?.title} /></Link> : <Img fluid={logoimage?.fluid} alt={logoimage?.title} />}
   </div>
  )
}

export default ArticleContentLogo
