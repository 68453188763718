import React from "react";
import { RichText } from "../../Layout/RichText/RichText";

import * as styles from './purpose-content.module.scss';
import { Link } from "gatsby";
import { useLocale, useUrl } from "../../../hooks";
import Img from 'gatsby-image';

const PurposeContent = ({ object }) => {
    const { alignment, content, cta, heading } = object
    const extraClass = alignment.toLocaleLowerCase()
    const baseUrl = useUrl()
    const locale = useLocale()
    return (
        <div className={`${styles.purposeInstagram} ${styles[extraClass]}`}>
            <p className={styles.instaHeading}><span>{heading}</span></p>
            {content &&
                <div className={styles.instaRichtext}>
                    <RichText content={content} />
                </div>}
            {
                cta.length > 1
                    ? <div className={styles.flexContainer}>
                        {cta.map(({ slug, image }) => {
                            return (
                                <div className={styles.instaIcon}>
                                    {<Link to={slug}>
                                        <Img fluid={image[0].fluid} alt={image[0].title} />
                                    </Link>}
                                </div>
                            )
                        })}
                    </div>
                    : <div className={styles.flexContainer}>
                        <div className={styles.instaIcon}>
                            <a href={cta[0]?.slug}>
                                <Img fluid={cta[0]?.image[0]?.fluid} alt={cta[0]?.image[0]?.title} />
                            </a>
                        </div></div>
            }
        </div>
    )
}

export default PurposeContent