import React from "react"
import SectionSignupForm from "../../sections/SectionSignupForm/SectionSignupForm"
import * as styles from "./CtaButton.module.scss"

const CtaButton = ({ ctaText, form, toggleForm }) => {
  return (
    <div className={styles.ctaContainer}>
      <button onClick={toggleForm} className={styles.ctaButton}>
        {ctaText}
      </button>
      {form && <SectionSignupForm toggleForm={toggleForm} />}
    </div>
  )
}

export default CtaButton
