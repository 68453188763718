import React, { useState, useEffect } from "react"
import * as styles from "./SectionSignupForm.module.scss" // Ensure this file contains your SCSS styles
import Spinner from "../../Spinner/Spinner"

const SectionSignupForm = ({ toggleForm }) => {
  return (
    <div>
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.btnDiv}>
            <button className={styles.closeButton} onClick={toggleForm}>
            &#10006;
            </button>
          </div>

          <iframe
            src="https://r1.dotdigital-pages.com/p/7HZU-7Q3/hcp-newsletter-signup"
            title="External Form"
            width="100%"
            height="85%"
            style={{ border: "none" }}
          />
        </div>
      </div>
    </div>
  )
}

export default SectionSignupForm
